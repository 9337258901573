<template>
  <v-menu
    offset-y
    z-index="10"
    nudge-bottom="10"
    nudge-left="105"
    min-width="240"
    max-width="240"
    content-class="account-menu"
    :close-on-content-click="true">
    <template #activator="{ on }">
      <div
        class="pointer"
        v-on="on">
        <AvatarImage
          error-action="getUserAvatar"
          :avatar-key="userAvatarKey"
          :avatar="userAvatar"
          :size="40" />
      </div>
    </template>
    <AccountDropdown />
  </v-menu>
</template>
<script>
import { mapState } from 'vuex';
import AccountDropdown from './AccountDropdown.vue';
export default {
  name: 'AppHeaderAccountMenu',
  components: {
    AccountDropdown,
  },
  computed: {
    ...mapState('UserProfile', ['userAvatar', 'userAvatarKey']),
  },
};
</script>